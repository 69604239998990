import {
  Game,
  GameLiveStatus,
  GameLiveStatusPeriodScore,
  Market,
  Odd,
  TranslatedCountry,
  TranslatedGameCategory,
  TranslatedLeague,
  TranslatedSport,
} from '@arland-bmnext/api-data'

import dayjs from 'dayjs'

export class MergedLiveState {
  public games: Map<string, Game>
  public gameForMarketLookup: Map<string, string>
  public marketForOddLookup: Map<string, string>
  public gameForLiveStatusLookup: Map<string, string>
  public liveStatusForPeriodScoreLookup: Map<string, string>

  public sports: Map<string, TranslatedLiveSportItem>
  public leagues: Map<string, TranslatedLeague>
  public countries: Map<string, TranslatedCountry>
  public gameCategories: Map<string, TranslatedGameCategory>

  constructor() {
    this.games = new Map()
    this.gameForMarketLookup = new Map()
    this.marketForOddLookup = new Map()
    this.gameForLiveStatusLookup = new Map()
    this.liveStatusForPeriodScoreLookup = new Map()
    this.sports = new Map()
    this.leagues = new Map()
    this.countries = new Map()
    this.gameCategories = new Map()
  }
}

export type TranslatedLiveSportItem = TranslatedSport & {
  openGameCount: number
}

export const removeGameMapEntry = (_mergedLiveState: MergedLiveState, gameId: number) => {
  const gameKey = gameId.toString()
  const game = _mergedLiveState.games.get(gameKey)

  if (game == null || game.markets == null) return

  for (let market of game.markets) {
    removeMarketMapEntry(_mergedLiveState, market.id)
  }

  removeGameLiveStatusMapEntry(_mergedLiveState, game.liveStatusId)

  _mergedLiveState.games.delete(gameKey)
}

export const removeMarketMapEntry = (_mergedLiveState: MergedLiveState, marketId: number) => {
  const marketKey = marketId.toString()
  const gameKey = _mergedLiveState.gameForMarketLookup.get(marketKey)
  const game = _mergedLiveState.games.get(gameKey)

  _mergedLiveState.gameForMarketLookup.delete(marketKey)

  if (game == null || game.markets == null) return

  for (let odd of game.markets?.find((market) => market.id === marketId)?.odds) {
    removeOddMapEntry(_mergedLiveState, odd.id)
  }
}

export const removeOddMapEntry = (_mergedLiveState: MergedLiveState, oddId: number) => {
  const oddKey = oddId.toString()
  _mergedLiveState.marketForOddLookup.delete(oddKey)
}

export const removeGameLiveStatusMapEntry = (_mergedLiveState: MergedLiveState, gameLiveStatusId: number) => {
  const gameLiveStatusKey = gameLiveStatusId.toString()
  const gameKey = _mergedLiveState.gameForLiveStatusLookup.get(gameLiveStatusKey)

  if (gameKey) {
    const game = _mergedLiveState.games.get(gameKey)
    if (game?.liveStatus?.periodScores != null) {
      for (let periodScore of game?.liveStatus?.periodScores) {
        removePeriodScoreMapEntry(_mergedLiveState, periodScore.id)
      }
    }
  }

  _mergedLiveState.gameForLiveStatusLookup.delete(gameLiveStatusKey)
}

export const removePeriodScoreMapEntry = (_mergedLiveState: MergedLiveState, periodScoreId: number) => {
  const periodScoreKey = periodScoreId.toString()
  _mergedLiveState.liveStatusForPeriodScoreLookup.delete(periodScoreKey)
}

export const updateGameMapEntry = (_mergedLiveState: MergedLiveState, game: Game) => {
  const gameKey = game.id.toString()
  _mergedLiveState.games.set(gameKey, { ...game })
  for (let market of game?.markets) {
    updateMarketMapEntry(_mergedLiveState, market, gameKey)
  }
  updateGameLiveStatusMapEntry(_mergedLiveState, game.liveStatus, gameKey, game.liveStatusId)
}

export const updateMarketMapEntry = (_mergedLiveState: MergedLiveState, market: Market, gameKey: string) => {
  const marketKey = market.id.toString()
  _mergedLiveState.gameForMarketLookup.set(marketKey, gameKey)
  for (let odd of market?.odds) {
    updateOddMapEntry(_mergedLiveState, odd, marketKey)
  }
}

export const updateOddMapEntry = (_mergedLiveState: MergedLiveState, odd: Odd, marketKey: string) => {
  const oddKey = odd.id.toString()
  _mergedLiveState.marketForOddLookup.set(oddKey, marketKey)
}

export const updateGameLiveStatusMapEntry = (
  _mergedLiveState: MergedLiveState,
  gameLiveStatus: GameLiveStatus,
  gameKey: string,
  gameLiveStatusId: number,
) => {
  if (gameLiveStatusId == null && gameLiveStatus == null) return

  const id = gameLiveStatusId || gameLiveStatus.id
  const liveStatusKey = id.toString()

  _mergedLiveState.gameForLiveStatusLookup.set(liveStatusKey, gameKey)

  if (gameLiveStatus != null && gameLiveStatus.periodScores != null) {
    for (let periodScore of gameLiveStatus?.periodScores) {
      updatePeriodScoreMapEntry(_mergedLiveState, periodScore, liveStatusKey)
    }
  }
}

export const updatePeriodScoreMapEntry = (
  _mergedLiveState: MergedLiveState,
  periodScore: GameLiveStatusPeriodScore,
  liveStatusKey: string,
) => {
  const periodScoreKey = periodScore.id.toString()
  _mergedLiveState.liveStatusForPeriodScoreLookup.set(periodScoreKey, liveStatusKey)
}

export const filterUnavailableGames = (games: Game[]): Game[] => {
  return games?.filter(
    (game) =>
      (game.markets?.length === 0 && dayjs().diff(dayjs(game.startDate), 'hours') <= 24) || game.markets?.length > 0,
  )
}
